import { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useFieldArray, useForm } from "react-hook-form";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";

const AttendanceAddSession = ({ edit }) => {
  const [values, setValues] = useState(null);
  const [lectureData, setLectureData] = useState([]);
  const { lectureID, sessionID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditSession": {
        return {
          ...state,
          submitAddEditSession: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditSession: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_UPDATE_ATTENDANCE_SESSION_API
    : process.env.REACT_APP_ADMIN_ADD_ATTENDANCE_SESSION_API;

  //!--------- add edit exam -------
  const [SessionAddEditSuccess, SessionAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditSession.flag,
    state.submitAddEditSession.dependency,
    state.submitAddEditSession.data,
    true
  );

  // get all lectures in exam
  const [allLecturesData] = useAxios(
    `${process.env.REACT_APP_ADMIN_GET_ALL_LECTURES_DATA_API}/${lectureID}`,
    "GET",
    "GET",
    ""
  );

  //!--------- get the exam info for editing -------
  const [SessionInfo] = useAxios(
    `${process.env.REACT_APP_ADMIN_GET_ATTENDANCE_SESSION_INFO_API}/${sessionID}`,
    "GET",
    edit,
    edit
  );

  useEffect(() => {
    if (SessionInfo) {
      let temp = SessionInfo.data;
      delete temp.img;
      setValues({
        ...temp,
      });
    }
  }, [SessionInfo]);

  useEffect(() => {
    if (SessionAddEditSuccess) {
      navigate(-1);
      reset();
    }
    //! make the dependency false again to listen to the next try to submit
  }, [SessionAddEditSuccess]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    control,
  } = useForm({
    mode: "onTouched",
    values,
    defaultValues: {
      sessions: [{ lecture_id: "" }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    name: "sessions",
    control,
  });

  useEffect(() => {
    if (SessionInfo) {
      const { lectures } = SessionInfo.data;
      reset({
        sessions: lectures.map((lecture) => ({ lecture_id: lecture.key })),
      });
    }
  }, [SessionInfo, reset]);

  const onSubmit = (data) => {
    const finalData = lectureID
      ? {
          ...data,
          id: lectureID,
        }
      : {
          ...data,
        };
    dispatch({
      type: "setSubmitAddEditSession",
      payload: {
        flag: "AddEditLecture",
        dependency: !state.submitAddEditSession.dependency,
        data: finalData,
      },
    });
  };
  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Add Session | Mr.Ahmed Sabry"></HelmetTags>
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          {edit
            ? "Edit Session"
            : "Please fill out the information to add the Session"}
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-fit  w-3/4 md:w-full flex-col items-center justify-start gap-6 rounded-3xl bg-white p-10 shadow-3xl"
        >
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10">
            <div className="name flex w-1/2 flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="name">Name</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="text"
                id="name"
                placeholder="Name"
                name="name"
                autoComplete="on"
                {...register("name", {
                  required: true,
                  //pattern: /^[A-Za-z]+$/,
                  maxLength: 40,
                  minLength: 3,
                })}
              />
              {errors.name && (
                <p className="w-full text-xl text-blue-900  ">
                  {errors.name.type === "required" &&
                    "Please fill out this field"}
                  {errors.name.type === "pattern" &&
                    "Only English letters are allowed"}
                  {errors.name.type === "maxLength" &&
                    "The maximum number of characters is 40 characters"}
                  {errors.name.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}

              {
                //!-------server errors -----

                SessionAddEditErrors &&
                  SessionAddEditErrors?.response?.data?.errors?.name && (
                    <p className="w-full text-xl text-blue-900  ">
                      {SessionAddEditErrors?.response?.data?.errors?.name[0]}
                    </p>
                  )
              }
            </div>
            <div className="name flex w-1/2 flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="days">Days</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="number"
                id="days"
                placeholder="Days"
                name="days"
                autoComplete="on"
                {...register("days", {
                  required: true,
                  //pattern: /^[A-Za-z]+$/,
                })}
              />
              {errors.days && (
                <p className="w-full text-xl text-blue-900  ">
                  {errors.days.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}

              {
                //!-------server errors -----

                SessionAddEditErrors &&
                  SessionAddEditErrors?.response?.data?.errors?.days && (
                    <p className="w-full text-xl text-blue-900  ">
                      {SessionAddEditErrors?.response?.data?.errors?.days[0]}
                    </p>
                  )
              }
            </div>
          </div>

          {fields.map((field, index) => (
            <div
              key={field.id}
              className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 "
            >
              <div
                className={`flex w-full flex-col items-end justify-center gap-2  md:w-full `}
              >
                <label
                  className="w-full truncate"
                  htmlFor={`lecture_${index + 1}`}
                >
                  Lecture
                </label>
                <select
                  name={`lecture_${index + 1}`}
                  id={`lecture_${index + 1}`}
                  {...register(`sessions.${index}.lecture_id`, {
                    required: { value: false, message: "lecture is required" },
                  })}
                >
                  <option value="" disabled selected>
                    Select a Lecture
                  </option>
                  {allLecturesData?.data?.map((lecture) => (
                    <option key={lecture.key} value={lecture.key}>
                      {lecture.name}
                    </option>
                  ))}
                </select>
                {errors?.sessions?.[index]?.lecture_id && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors?.sessions?.[index]?.lecture_id.type ===
                      "required" && "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  SessionAddEditErrors &&
                    SessionAddEditErrors?.response?.data?.errors?.sessions?.[
                      index
                    ]?.lecture_id && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {
                          SessionAddEditErrors?.response?.data?.errors
                            ?.sessions?.[index]?.lecture_id[0]
                        }
                      </p>
                    )
                }
              </div>
              <div className="delete-set my-auto">
                <button
                  onClick={() => remove(index)}
                  className={` bg-red-00 group mt-7 rounded-xl p-3 duration-300 hover:bg-secondary   active:scale-90 ${
                    index === 0 && "pointer-events-none opacity-0"
                  }`}
                >
                  <FontAwesomeIcon
                    className=" text-3xl duration-300 group-hover:text-light group-active:scale-90"
                    icon={faTrash}
                  />
                </button>
              </div>
            </div>
          ))}

          <button
            type="button"
            onClick={() => append({ lecture_id: "" })}
            className="mt-4 rounded-xl border-2 border-accent bg-accent px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-accent   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add Lecture
          </button>

          {/** submit */}

          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditSession.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditSession.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>{edit ? "Confirm Edit Session" : "Add Session"}</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default AttendanceAddSession;
