import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SingleCourse from "../../../../MainComponents/SingleCourse";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";

function AdminExams() {
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const [allExamsData, allExamsErrors, allExamsloading] = useAxios(
    process.env.REACT_APP_ADMIN_TRIAL_EXAMS_TAB_API,
    "GET",
    "GET",
    reFetch
  );

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title="Exams | Mr.Ahmed Sabry"></HelmetTags>
      <div className="mb-32 flex flex-col gap-10">
        <h2 className="w-full text-4xl font-bold md:text-center">Exams</h2>{" "}
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("exam_add")
          )) && (
          <div className="flex w-full md:justify-evenly">
            <Link
              to="/admin/exams/add-exam"
              className="rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add Exam
            </Link>
          </div>
        )}
        {allExamsloading ? (
          <Loader />
        ) : (
          <div className="lectures grid-auto-fit w-full">
            {allExamsData?.data?.map((Card) => (
              <SingleCourse
                reFetch={reFetch}
                setReFetch={setReFetch}
                admin
                exam
                lecture={Card}
                key={Card.key}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default AdminExams;
