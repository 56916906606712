//!------edit in table ------

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "../../../../MainComponents";

function AddEditCategory({ edit }) {
  const navigate = useNavigate();
  const { categoryID } = useParams();
  const [values, setValues] = useState(null);

  const [submitCategory, setSubmitCategory] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const addEditApi = edit
    ? process.env.REACT_APP_EDIT_CATEGORY_API
    : process.env.REACT_APP_ADD_CATEGORY_API;

  const [addCategorySuccess, addCategoryErrors, submitLoading] = useAxios(
    addEditApi,
    "POST",
    submitCategory.flag,
    submitCategory.dependency,
    submitCategory.data,
    true
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
    setValue,
  } = useForm({ mode: "onTouched", values });

  const watchType = watch("edu_type");
  const watchLevel = watch("stage");

  const [categoryInfo, categoryInfoErrors, categoryInfoLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_GET_CATEGORY_INFO_API}/${categoryID}`,
    "GET",
    categoryID,
    categoryID
  );

  useEffect(() => {
    if (categoryInfo) {
      let temp = categoryInfo.data;
      setValues({
        ...temp,
        edu_type: String(temp.edu_type),
        stage: String(temp.stage),
        section: temp.section,
      });
    }
  }, [categoryInfo]);

  const onSubmit = (data) => {
    setSubmitCategory({
      flag: "addCategoryRequest",
      dependency: !submitCategory.dependency,
      data: { ...data, id: categoryID },
    });
  };

  useEffect(() => {
    if (addCategorySuccess) {
      reset();
      navigate(-1);
    }
  }, [addCategorySuccess]);

  if (categoryInfoLoading) return <Loader />;

  return (
    <section className="flex h-full w-full flex-col items-center dark:bg-dark dark:text-light">
      <HelmetTags title="Categories | Mr Ibrahim Abd Elmasseh"></HelmetTags>
      <div className="form-container my-20 flex flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          Please fill out the information to add the category
        </h2>

        <form
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-fit w-[380px] flex-col  items-center justify-start gap-6 rounded-3xl bg-white p-10 shadow-3xl md:w-[250px] "
        >
          {/** Category name */}

          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate" htmlFor="name">
              Category name
            </label>
            <input
              className="signin-inputs  w-full "
              type="text"
              id="name"
              placeholder="Enter the category name"
              name="name"
              autoComplete="on"
              {...register("name", {
                required: true,
                maxLength: 100,
                minLength: 3,
              })}
            />
            {errors.name && (
              <p className="text-[12px] text-blue-900 ">
                {errors.name.type === "required" &&
                  "Please fill out this field"}
                {errors.name.type === "maxLength" &&
                  "The maximum number of characters is 100 characters."}
                {errors.name.type === "minLength" &&
                  "Please write at least 3 characters"}
              </p>
            )}
            {
              //!-------server errors -----

              addCategoryErrors &&
                addCategoryErrors?.response?.data?.errors?.name && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {addCategoryErrors?.response?.data?.errors?.name[0]}
                  </p>
                )
            }
          </div>

          <div className="flex w-full flex-col  items-center md:order-2 md:w-full">
            <label
              className="text-start flex items-center gap-2"
              htmlFor="Type"
            >
              Type
            </label>
            <div className="flex w-full gap-20 pb-1 sm:flex-col-reverse">
              {/** ig  */}
              <div className=" flex  items-center justify-center gap-2 ">
                <input
                  className="cursor-pointer"
                  type="radio"
                  id="ig"
                  value="0"
                  {...register("edu_type", {
                    required: true,
                  })}
                />
                <label className="cursor-pointer text-start" htmlFor="ig">
                  IG
                </label>
              </div>

              {/** national */}
              <div className=" flex  items-center justify-center gap-2 ">
                <input
                  className="cursor-pointer"
                  type="radio"
                  id="national"
                  value="1"
                  {...register("edu_type", {
                    required: true,
                  })}
                />
                <label className="cursor-pointer text-start" htmlFor="national">
                  National
                </label>
              </div>
            </div>
            {errors.edu_type && (
              <p className="w-full text-start text-[12px] text-blue-900  ">
                {errors.edu_type.type === "required" &&
                  "Please fill out this field"}
              </p>
            )}

            {
              //!-------Editing server errors -----

              addCategoryErrors?.response?.data?.errors &&
                addCategoryErrors?.response?.data?.errors?.edu_type && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {addCategoryErrors?.response?.data.errors.edu_type[0]}
                  </p>
                )
            }
          </div>

          {watchType !== null && (
            <div
              className={`flex w-full flex-col items-end justify-center gap-2 md:order-2  md:w-full `}
            >
              <label className="w-full truncate" htmlFor="stage">
                Classroom
              </label>

              <select
                name="stage"
                id="stage"
                {...register("stage", { required: true })}
              >
                <option value="" disabled selected>
                  Select a stage
                </option>
                {watchType === "0" ? (
                  <>
                    <option value="9">Nine Year</option>
                    <option value="10">Ten Year</option>
                  </>
                ) : (
                  <>
                    <option value="22">Second preparatory</option>
                    <option value="33">Third preparatory </option>
                    <option value="1">First Secondary</option>
                    <option value="2">Second Secondary</option>
                    <option value="3">Third Secondary</option>
                  </>
                )}
              </select>

              {errors.stage && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.stage.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                addCategoryErrors &&
                  addCategoryErrors?.response?.data?.errors?.stage && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {addCategoryErrors?.response?.data?.errors?.stage[0]}
                    </p>
                  )
              }
            </div>
          )}
          {watchType === "1" && (
            <>
              {+watchLevel === 2 || +watchLevel === 3 ? (
                <>
                  <div className="flex w-full items-end gap-20 pb-1 sm:flex-col-reverse">
                    {/** scientific  */}

                    <div className=" flex  items-center justify-center gap-2 ">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        id="scientific"
                        value="scientific"
                        {...register("section", {
                          required: true,
                        })}
                      />
                      <label
                        className="cursor-pointer text-start"
                        htmlFor="scientific"
                      >
                        Scientific
                      </label>
                    </div>

                    {/** arts  */}
                    <div className=" flex  items-center justify-center gap-2 ">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        id="arts"
                        value="arts"
                        {...register("section", {
                          required: true,
                        })}
                      />
                      <label
                        className="cursor-pointer text-start"
                        htmlFor="arts"
                      >
                        Arts
                      </label>
                    </div>
                  </div>
                  {errors.section && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {errors.section.type === "required" &&
                        "Please fill out this field"}
                    </p>
                  )}

                  {
                    //!-------Editing server errors -----

                    addCategoryErrors?.response?.data?.errors &&
                      addCategoryErrors?.response?.data?.errors?.section && (
                        <p className="w-full text-start text-[12px] text-blue-900  ">
                          {addCategoryErrors?.response?.data.errors.section[0]}
                        </p>
                      )
                  }
                </>
              ) : (
                ""
              )}
            </>
          )}

          {/** submit */}

          <button
            disabled={!isValid || (submitLoading && submitCategory.flag)}
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && submitCategory.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Add classification"
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditCategory;
