import { Link } from "react-router-dom";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import HelmetTags from "../../../../MainComponents/HelmetTags";

function Groups() {
  const table = useTable("groups");
  const AuthState = useAuthState();

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Groups | Mr Ibrahim Abd Elmasseh"></HelmetTags>
      <div className="mb-20 flex flex-col items-start gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">Groups</h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("group_add")
          )) && (
          <Link
            to="/admin/groups/add-group"
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add group
          </Link>
        )}
      </div>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default Groups;
