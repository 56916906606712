import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faAddressCard,
  faBuildingUser,
  faChalkboardUser,
  faLocationDot,
  faMobileScreenButton,
  faSchoolFlag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Popconfirm } from "antd";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import {
  useAuthState,
  useAuthDispatch,
} from "../../../../MainComponents/GlobalContext";
import { Loader } from "../../../../MainComponents";

function Requests() {
  const AuthState = useAuthState();
  const AuthDispatch = useAuthDispatch();

  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [submitAccept, setSubmitAccept] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [reFetch, setReFetch] = useState(false);

  //!--------- all Requests request -------

  const [allRequestsData, allRequestsErrors, loading] = useAxios(
    process.env.REACT_APP_ALL_REQUESTS_API,
    "GET",
    "GET",
    reFetch
  );
  //!--------- delete Request -------

  const [deleteRequestSuccess, deleteRequestErrors] = useAxios(
    process.env.REACT_APP_DELETE_REQUEST_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  //!--------- accept Request -------

  const [acceptRequestSuccess, acceptRequestErrors] = useAxios(
    process.env.REACT_APP_ACCEPT_REQUEST_API,
    "POST",
    submitAccept.flag,
    submitAccept.dependency,
    submitAccept.data,
    true
  );
  useEffect(() => {
    //! refetch the data on success delete or accept request

    if (deleteRequestSuccess || acceptRequestSuccess) {
      setReFetch(!reFetch);
      AuthDispatch({
        type: "setRefetchIfAcceptOrDeleteRequest",
      });
    }
  }, [deleteRequestSuccess, acceptRequestSuccess]);

  function handleDeleteReq(id) {
    setSubmitDelete({
      flag: "deleteRequest",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  function handleAcceptReq(id) {
    setSubmitAccept({
      flag: "acceptRequest",
      dependency: !submitAccept.dependency,
      data: { id: id },
    });
  }
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <HelmetTags title="Requests | Mr Ibrahim Abd Elmasseh"></HelmetTags>

      <section className="h-fit w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
        <h2 className="mb-16 w-full  text-4xl font-bold md:text-center">
          Inactive students
        </h2>

        {allRequestsData?.data?.length === 0 ? (
          <p className="w-full text-center">No new requests</p>
        ) : allRequestsErrors ? (
          <p className="w-full text-center">{allRequestsErrors}</p>
        ) : (
          allRequestsData?.data?.map((std) => (
            <div
              key={std.key}
              //!single student
              className="mx-auto mb-20 flex flex-row-reverse h-fit w-5/6 items-center justify-between gap-12 rounded-[50px]  p-6 lg:w-full amd:h-auto amd:flex-col-reverse amd:gap-16"
            >
              <div
                //!std-left
                className="std-details amd: flex h-full w-3/5 items-center justify-evenly  gap-5 rounded-3xl border-2 border-secondary p-5 amd:w-full flex-row-reverse sm:flex-col-reverse sm:gap-14 sm:p-10"
              >
                <div className="btns flex flex-col items-center justify-center gap-7 sm:flex-row">
                  {(AuthState.userData?.admin?.roles[0]?.name ===
                    "superadmin" ||
                    AuthState.userData?.admin?.permissions?.some((permission) =>
                      permission.name.startsWith("request_accept")
                    )) && (
                    <button
                      onClick={() => handleAcceptReq(std.key)}
                      className="approve-btn w-28  rounded-xl border-2 border-save bg-save py-[5px] text-center text-2xl text-light transition-all duration-300 hover:scale-105 hover:bg-transparent hover:text-save active:scale-90"
                    >
                      Accept
                    </button>
                  )}
                  {(AuthState.userData?.admin?.roles[0]?.name ===
                    "superadmin" ||
                    AuthState.userData?.admin?.permissions?.some((permission) =>
                      permission.name.startsWith("request_delete")
                    )) && (
                    <Popconfirm
                      okText="Confirm"
                      okType="red"
                      cancelText="Cancel"
                      color="#b1e8f4"
                      //okButtonProps={{ loading: loading }}
                      onConfirm={() => handleDeleteReq(std.key)}
                      title="Do you really want to delete the student's request?"
                    >
                      <button className="approve-btn w-28  rounded-xl border-2 border-accent bg-accent py-[5px] text-center text-2xl text-light transition-all duration-300 hover:scale-105 hover:bg-transparent  hover:text-accent active:scale-90">
                        Delete
                      </button>
                    </Popconfirm>
                  )}
                </div>
                <div className="std-info flex h-auto w-fit flex-col justify-start gap-8 ">
                  <div className="flex justify-start">
                    <div className="w-8 min-w-[32px]  text-3xl ">
                      <FontAwesomeIcon
                        className=""
                        icon={faMobileScreenButton}
                      />
                    </div>
                    <h4 className=" text-[17px] font-semibold ">
                      Phone:{" "}
                      <span className="text-[15px] opacity-80">
                        {std.mobile}
                      </span>
                    </h4>
                  </div>
                  <div className="flex justify-start">
                    <div className="w-8 min-w-[32px]  text-3xl ">
                      <FontAwesomeIcon
                        className=""
                        icon={faMobileScreenButton}
                      />
                    </div>
                    <h4 className=" text-[17px] font-semibold ">
                      Parent's phone 1:
                      <span className="text-[15px] opacity-80 xs:text-center">
                        {std.parent_mobile}
                      </span>
                    </h4>
                  </div>
                  <div className="flex justify-start">
                    <div className="w-8 min-w-[32px]  text-3xl ">
                      <FontAwesomeIcon
                        className=""
                        icon={faMobileScreenButton}
                      />
                    </div>
                    <h4 className=" text-[17px] font-semibold ">
                      Parent’s phone 2:
                      <span className="text-[15px] opacity-80 xs:text-center">
                        {std.parent_mobile2}
                      </span>
                    </h4>
                  </div>
                  <div className="flex justify-start">
                    <div className="w-8 min-w-[32px]  text-3xl ">
                      <FontAwesomeIcon
                        className=""
                        icon={faMobileScreenButton}
                      />
                    </div>
                    <h4 className=" text-[17px] font-semibold ">
                      Type:{" "}
                      <span className="text-[15px] opacity-80">
                        {std.edu_type}
                      </span>
                    </h4>
                  </div>
                  <div className="flex justify-start">
                    <div className="w-8 min-w-[32px] text-3xl">
                      <FontAwesomeIcon className="" icon={faLocationDot} />
                    </div>
                    <div className=" text-[17px] font-semibold">
                      <h5 className="inline-block">City: </h5>
                      <span className="text-[15px] opacity-80">{std.area}</span>
                    </div>
                  </div>

                  <div className="flex justify-start">
                    <div className="w-8 min-w-[32px] text-3xl">
                      <FontAwesomeIcon
                        className=""
                        icon={
                          std.is_online === "Center"
                            ? faBuildingUser
                            : faChalkboardUser
                        }
                      />
                    </div>
                    <div className=" text-[17px] font-semibold ">
                      <h5 className="inline-block">Place: </h5>
                      <span className="text-[15px] opacity-80">
                        {std.is_online}
                      </span>
                    </div>
                  </div>
                  {std.section && (
                    <div className="flex justify-start">
                      <div className=" text-[17px] font-semibold ">
                        <h5 className="inline-block">Department: </h5>
                        <span className="text-[15px] opacity-80">
                          {std.section}
                        </span>
                      </div>
                      <div className="w-8 min-w-[32px]   text-3xl ">
                        <FontAwesomeIcon className="" icon={faAddressCard} />
                      </div>
                    </div>
                  )}
                  <div className="flex justify-start">
                    <div className="w-8 min-w-[32px]   text-3xl ">
                      <FontAwesomeIcon className="" icon={faSchoolFlag} />
                    </div>
                    <div className=" text-[17px] font-semibold ">
                      <h5 className="inline-block">School: </h5>
                      <span className="text-[15px] opacity-80">
                        {std.school}
                      </span>
                    </div>
                  </div>

                  <div className="flex justify-start">
                    <div className="w-8 min-w-[32px]  text-3xl ">
                      <FontAwesomeIcon className="" icon={faClock} />
                    </div>
                    <h4 className=" text-[17px] font-semibold ">
                      Release Date:
                      <span className="text-[15px] opacity-80">
                        {std.created_at}
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
              <div
                //!std-right
                className="std-img flex h-full w-2/5 flex-col items-center justify-center gap-9 amd:w-full "
              >
                <div className="std-img bg-green h-96 w-96 overflow-hidden rounded-xl border-2 border-secondary/40 p-4">
                  <div className="h-full w-full rounded-xl border-2 border-secondary/60 object-cover object-top p-4">
                    <img
                      className="h-full w-full rounded-xl border-2 border-secondary object-cover object-top "
                      src={std.profile_pic}
                      alt={std.name}
                    />
                  </div>
                </div>
                <div className="name-stage flex items-center justify-center gap-4">
                  <h3 className=" border-r-2 border-r-secondary pr-4 text-center text-3xl font-semibold">
                    {std.name}
                  </h3>
                  <h4 className=" min-w-fit text-center text-3xl font-semibold opacity-80">
                    Class {std.stage}
                  </h4>
                </div>
              </div>
            </div>
          ))
        )}
      </section>
    </>
  );
}

export default Requests;
