import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faUser,
  faArrowRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { PiListNumbersFill } from "react-icons/pi";
import { BsFillPeopleFill } from "react-icons/bs";

import { useAuthDispatch, useAuthState } from "./GlobalContext.jsx";
import useAxios from "./Hooks/useAxios.jsx";

function DropDownMenu({ scroll }) {
  const AuthDispatch = useAuthDispatch();
  const AuthState = useAuthState();
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  function handleSignOut() {
    setShowMenu(false);

    localStorage.removeItem("userData");
    AuthDispatch({ type: "setUserData", payload: null });
    navigate("/signin");
  }
  return (
    <>
      {showMenu && (
        <div
          className="absolute inset-0 h-[100vh] w-[100vw]"
          onClick={() => setShowMenu(false)}
        ></div>
      )}
      <div
        className=" img-icon group relative  flex min-h-[] min-w-[] items-center justify-center gap-2 py-8"
        onClick={() => setShowMenu(!showMenu)}
      >
        <div
          className={`user-img-container h-12 min-h-[48px] w-12 min-w-[48px] overflow-hidden rounded-full border-2 p-1 transition-all duration-300 ease-in-out  ${
            scroll ? "border-light " : "border-secondary"
          } flex cursor-pointer items-center justify-center`}
        >
          <img
            src={`https://api.ibrahimabdelmaseeh.com/uploads/profile_picture/${AuthState.userData?.student?.profile_pic}`}
            alt="user-img"
            className="user-img w-full h-full rounded-full object-cover"
          />
        </div>
        <FontAwesomeIcon
          icon={faAngleDown}
          className={`cursor-pointer text-3xl transition-all duration-300 ease-in-out ${
            scroll ? "text-light " : "text-secondary"
          }  `}
        />

        <div
          className={`pointer-events-none absolute right-0 top-32 h-fit w-fit scale-90 opacity-0 transition-all duration-300 ease-in-out group-hover:pointer-events-auto group-hover:scale-100 group-hover:opacity-100 ${
            showMenu && "pointer-events-auto scale-100 opacity-100"
          }`}
        >
          <ul className="drop-menu-items   z-[150] flex h-[400px] w-96 flex-col items-start justify-start gap-10 rounded-xl border-2 border-secondary bg-light p-8 text-secondary shadow-md dark:border-2  dark:border-light dark:bg-dark dark:text-light">
            {/* <li className="flex cursor-pointer items-center justify-start gap-4 hover:opacity-80">
              <p className="text-2xl font-semibold">Points:</p>
              <span>{getAmountSucsses?.data?.point}</span>
            </li>
            <li className="flex cursor-pointer items-center justify-start gap-4 hover:opacity-80">
              <p className="text-2xl font-semibold">Amounts:</p>
              <span>{getAmountSucsses?.data?.amount}</span>
            </li> */}
            {/* 
            <li
              onClick={() => {
                navigate("/home/amount");
                setShowMenu(false);
              }}
              className="flex cursor-pointer items-center justify-start gap-4 hover:opacity-80"
            >
              <PiListNumbersFill />
              <p className="text-2xl font-semibold">Amount</p>
            </li> */}

            <li
              onClick={() => {
                navigate("/home/profile");
                setShowMenu(false);
              }}
              className="flex cursor-pointer items-center justify-start gap-4 hover:opacity-80"
            >
              <FontAwesomeIcon
                icon={faUser}
                className="cursor-pointer text-2xl"
              />
              <p className="text-2xl font-semibold">Profile</p>
            </li>
            <li
              onClick={() => handleSignOut()}
              className="flex cursor-pointer items-center justify-start gap-4 hover:opacity-80"
            >
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                className=" cursor-pointer text-2xl"
              />
              <p className="text-2xl font-semibold">Logout</p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default DropDownMenu;
