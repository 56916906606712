import { useEffect, useReducer, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import logoLight from "../../../assets/logo-light.png";
import Loader from "../../../MainComponents/Loader";

function Register({ edit, addNew, StudentInfo, studentInfoLoading }) {
  const [values, setValues] = useState(null);
  const { studentID } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
    values,
  });

  const watchCountries = watch("countries");
  const watchGovernorate = watch("governorate");
  const watchLevel = watch("stage");
  const watchType = watch("edu_type");
  const watchPlace = watch("is_online");

  function reducer(state, action) {
    switch (action.type) {
      case "setStates": {
        return {
          ...state,
          states: action.payload,
        };
      }
      case "setGroups": {
        return {
          ...state,
          groups: action.payload,
        };
      }
      case "setSelectedState": {
        return {
          ...state,
          selectedState: action.payload,
        };
      }
      case "setCitySelected": {
        return {
          ...state,
          citySelected: action.payload,
        };
      }
      case "setGovernorate": {
        return {
          ...state,
          governorate: action.payload,
        };
      }
      case "setCities": {
        return {
          ...state,
          cities: action.payload,
        };
      }
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setHide": {
        return {
          ...state,
          hide: !state.hide,
        };
      }
      case "setSubmitLoading": {
        return {
          ...state,
          submitLoading: action.payload,
        };
      }
      case "setSubmitEditStudent": {
        return {
          ...state,
          submitEditStudent: action.payload,
        };
      }
      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [selectedValue, setSelectedValue] = useState("");
  const [state, dispatch] = useReducer(reducer, {
    hide: false,
    states: [],
    governorate: [],
    cities: [],
    groups: [],
    selectedState: "",
    selectedCity: "",
    errors: {},
    submitLoading: false,
    submitEditStudent: { flag: "", dependency: false, data: {} },
  });

  useEffect(() => {
    // const stateSelector = document.querySelector(".state-selector");
    // const citySelector = document.querySelector(".city-selector");
    // stateSelector.addEventListener("change", function handleStateSelected(e) {
    //   dispatch({
    //     type: "setSelectedState",
    //     payload: e.target.selectedIndex,
    //   });
    // });
    // citySelector?.addEventListener("change", function handleStateSelected(e) {
    //   dispatch({
    //     type: "setCitySelected",
    //     payload: e.target.selectedIndex,
    //   });
    // });
    async function fetchData() {
      try {
        const res = await axios(process.env.REACT_APP_GOVERNORATES_API);
        const data = await res.data;
        dispatch({
          type: "setGovernorate",
          payload: data?.states,
        });
        // dispatch({
        //   type: "setStates",
        //   payload: data?.states,
        // });
        dispatch({
          type: "setGroups",
          payload: data?.groups,
        });
      } catch (error) {
        toast.error(
          "An error occurred while loading cities. Please try again."
        );
      }
    }
    fetchData();
  }, []);

  const [StudentEditSuccess, StudentEditErrors, StudentEditLoading] = useAxios(
    process.env.REACT_APP_EDIT_STUDENT_INFO_API,
    "POST",
    state.submitEditStudent.flag,
    state.submitEditStudent.dependency,
    state.submitEditStudent.data,
    true
  );

  useEffect(() => {
    if (StudentEditSuccess) {
      reset();
      navigate(-1);
    }
  }, [StudentEditSuccess]);

  useEffect(() => {
    if (StudentInfo) {
      dispatch({
        type: "setSelectedState",
        payload: StudentInfo?.data[0]?.selected_state,
      });
      setValue("governorate", StudentInfo?.data[0]?.governorate);
      setValue("is_online", StudentInfo?.data[0]?.is_online);
      // setValue("stage", StudentInfo?.data[0]?.stage);
      setValue("countries", StudentInfo?.data[0]?.country);

      delete StudentInfo?.data[0]?.governorate;
      delete StudentInfo?.data[0]?.is_online;
      setValues({
        ...StudentInfo?.data[0],
        active: StudentInfo?.data[0]?.active === 1 ? true : false,
        edu_type: String(StudentInfo?.data[0]?.edu_type),
        stage: String(StudentInfo?.data[0]?.stage),
      });
    }
  }, [StudentInfo]);

  useEffect(() => {
    if (watchCountries === "") {
      dispatch({
        type: "setGovernorate",
        payload: state?.states?.filter(
          (state) => state.Country === "مصر" //  egypt
        )[0]?.states,
      });

      setValue("countries", "مصر");
    }
  }, [StudentInfo?.data, setValue, state?.states, watchCountries]);

  useEffect(() => {
    if (watchGovernorate) {
      dispatch({
        type: "setCities",
        payload: state.governorate?.filter(
          (city) => city.state === watchGovernorate
        )[0]?.cities,
      });
    }

    if (watchGovernorate === "") {
      dispatch({
        type: "setCities",
        payload: state.governorate?.filter(
          (city) => city.state === "القاهرة"
        )[0]?.cities,
      });
      setValue("governorate", "القاهرة");
      setValue("area", 1);
    }
  }, [watchGovernorate, state.governorate]);

  const onSubmit = (data) => {
    const studentData = edit
      ? {
          ...data,
          group_id: data?.group_id || null,
          // profile_pic: data?.profile_pic[0] || null,
          magnetic_id: data?.magnetic_id || null,
          active: data?.active === true ? 1 : 0,
          id: studentID,
        }
      : {
          ...data,
          first_name: data?.first_name?.trim(),
          second_name: data?.second_name?.trim(),
          third_name: data?.third_name?.trim(),
          fourth_name: data?.fourth_name?.trim(),
          mobile: data?.mobile?.trim(),
          parent_mobile: data?.parent_mobile?.trim(),
          profile_pic: data?.profile_pic[0],
          parent_mobile2: data?.parent_mobile2?.trim() || null,
          school: data?.school || null,
          active: addNew ? 1 : 0,
        };

    if (edit) {
      dispatch({
        type: "setSubmitEditStudent",
        payload: {
          flag: "AddEditStudent",
          dependency: !state.submitEditStudent.dependency,
          data: studentData,
        },
      });
    } else {
      dispatch({
        type: "setSubmitLoading",
        payload: true,
      });
      async function postData() {
        try {
          const res = await axios.post(
            process.env.REACT_APP_REGISTER_API,
            studentData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );

          const data = await res.data;
          toast.success(data?.message);
          dispatch({
            type: "setSubmitLoading",
            payload: false,
          });
          !edit && navigate("/signin");
          reset();
        } catch (error) {
          dispatch({
            type: "setErrors",
            payload: error.response.data?.errors,
          });
          dispatch({
            type: "setSubmitLoading",
            payload: false,
          });
          toast.error(
            "An error occurred while creating a new account. Please review the data"
          );
        }
      }

      postData();
    }
  };

  if (studentInfoLoading) {
    return <Loader />;
  }

  return (
    <section className="flex h-fit min-h-screen flex-col items-center justify-center gap-0 w-full py-24 dark:bg-dark">
      {studentInfoLoading && <Loader />}

      <div
        className={`md:max-w-3/4 flex flex-col w-3/4 ${
          studentInfoLoading && "hidden"
        }`}
      >
        {!edit && !addNew ? (
          <>
            <HelmetTags
              title="Create an account | Mr Ibrahim Abd Elmasseh"
              description="Create an account | Mr Ibrahim Abd Elmasseh"
              index
            >
              <link
                rel="canonical"
                href="https://ibrahimabdelmaseeh.com/register"
              />
            </HelmetTags>

            <div className="mb-24 flex flex-col items-center  justify-center">
              {/* <Link className="h-60 text-4xl " to="/">
                <img className="h-full" src={logoLight} alt="logo" />
              </Link> */}
              <h1 className="mb-5 mt-10 font-bold ">
                New account | Mr Ibrahim Abd Elmasseh
              </h1>
              <p className=" opacity-80">
                Enter your details to create a new account
              </p>
            </div>
          </>
        ) : null}

        {edit ||
          (addNew && (
            <HelmetTags
              title="Students | Mr Ibrahim Abd Elmasseh"
              description="Create an account | Mr Ibrahim Abd Elmasseh"
              index
            ></HelmetTags>
          ))}

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className=" w-full flex flex-col items-start justify-center gap-6 rounded-3xl bg-white p-16 shadow-3xl"
        >
          <span className="text-red-700 text-3xl font-bold">* Required</span>

          {/** Name filed */}
          <div className="flex flex-row-reverse w-full items-start justify-between gap-6 md:flex-col-reverse ">
            {/* <div className="flex w-1/2 flex-col items-start justify-center  gap-2   md:w-full">
              <label
                className="text-start flex items-center gap-2"
                htmlFor="third_name"
              >
                Family name{" "}
                <span className="text-red-700 text-3xl font-bold">*</span>
              </label>
              <input
                className="signin-inputs w-full pl-4"
                type="text"
                id="third_name"
                placeholder="Family name"
                name="third_name"
                autoComplete="on"
                {...register("third_name", {
                  required: true,
                  pattern: /^[A-Za-z\s]+$/u,
                  maxLength: 15,
                  minLength: 3,
                })}
              />
              {errors.third_name && (
                <p className="w-full text-start text-[12px] text-blue-900  ">
                  {errors.third_name.type === "required" &&
                    "Please fill out this field"}
                  {errors.third_name.type === "pattern" &&
                    "Only English letters are allowed"}
                  {errors.third_name.type === "maxLength" &&
                    "The maximum number of characters is 15 characters"}
                  {errors.third_name.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.third_name && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.third_name[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.third_name && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.third_name[0]}
                    </p>
                  )
              }
            </div> */}

            {/** Second Name filed */}
            {/* <div className=" flex w-1/2 flex-col items-start justify-center  gap-2    md:w-full">
              <label
                className="text-start flex items-center gap-2"
                htmlFor="second_name"
              >
                Middle name{" "}
                <span className="text-red-700 text-3xl font-bold">*</span>
              </label>
              <input
                className="signin-inputs  w-full pl-4"
                type="text"
                id="second_name"
                placeholder="Middle name"
                name="second_name"
                autoComplete="on"
                {...register("second_name", {
                  required: true,
                  pattern: /^[A-Za-z\s]+$/u,

                  maxLength: 15,
                  minLength: 3,
                })}
              />
              {errors.second_name && (
                <p className="w-full text-start text-[12px] text-blue-900  ">
                  {errors.second_name.type === "required" &&
                    "Please fill out this field"}
                  {errors.second_name.type === "pattern" &&
                    "Only English letters are allowed"}
                  {errors.second_name.type === "maxLength" &&
                    "The maximum number of characters is 15 characters"}
                  {errors.second_name.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}

              {
                //!-------server errors -----

                state.errors && state.errors.second_name && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.second_name[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.second_name && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.second_name[0]}
                    </p>
                  )
              }
            </div> */}

            {/** First Name filed */}
            <div className=" flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label
                className="text-start flex items-center gap-2"
                htmlFor="first_name"
              >
                Name
                <span className="text-red-700 text-3xl font-bold">*</span>
              </label>
              <input
                className="signin-inputs  w-full pl-4"
                type="text"
                id="first_name"
                placeholder="Name"
                name="first_name"
                autoComplete="on"
                {...register("first_name", {
                  required: true,
                  pattern: /^[A-Za-z\s]+$/u,
                  maxLength: 15,
                  minLength: 3,
                })}
              />
              {errors.first_name && (
                <p className="w-full text-start text-[12px] text-blue-900  ">
                  {errors.first_name.type === "required" &&
                    "Please fill out this field"}
                  {errors.first_name.type === "pattern" &&
                    "Only English letters are allowed"}
                  {errors.first_name.type === "maxLength" &&
                    "The maximum number of characters is 15 characters"}
                  {errors.first_name.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}

              {
                //!-------server errors -----

                state.errors && state.errors.first_name && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.first_name[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.first_name && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.first_name[0]}
                    </p>
                  )
              }
            </div>
          </div>

          {/** Father's Mobile Numbers fields */}
          <div className="flex flex-row-reverse  w-full items-start justify-between gap-6 md:flex-col-reverse">
            {/** Father's WhatsUp field 2 */}

            {/* <div className={`w-1/3 md:w-full`}>
              <div className="  flex w-full flex-col items-start justify-center gap-2">
                <label className="text-start" htmlFor="parent_mobile2">
                  Parent No. 2
                </label>
                <div className="relative w-full">
                  <input
                    className="signin-inputs pl-4   w-full "
                    type="number"
                    id="parent_mobile2"
                    placeholder="Parent Mobile 2"
                    name="parent_mobile2"
                    autoComplete="on"
                    {...register("parent_mobile2", {
                      required: false,
                      pattern: /^[\d]{11}/,
                      maxLength: 11,
                      validate: (value) => value !== getValues("parent_mobile"),
                    })}
                  />
                  <FontAwesomeIcon
                    className="absolute bottom-1/2 right-4 h-6  w-7 translate-y-1/2"
                    icon={faWhatsapp}
                  />
                </div>
              </div>
              {errors.parent_mobile2 && (
                <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                  {errors.parent_mobile2.type === "required" &&
                    "Please fill out this field"}
                  {errors.parent_mobile2.type === "pattern" &&
                    "Please enter an 11-digit phone number"}
                  {errors.parent_mobile2.type === "maxLength" &&
                    "Please enter an 11-digit phone number"}
                  {errors.parent_mobile2.type === "validate" &&
                    "Please enter a different phone number"}
                </p>
              )}

              {
                //!-------server errors -----

                state.errors && state.errors.parent_mobile2 && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.parent_mobile2[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.parent_mobile2 && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {
                        StudentEditErrors?.response?.data.errors
                          .parent_mobile2[0]
                      }
                    </p>
                  )
              }
            </div> */}
            {/** Father's WhatsUp field 1 */}
            {/* <div className={`w-1/3 md:w-full`}>
              <div className="  flex w-full flex-col items-start justify-center gap-2">
                <label
                  className="text-start flex items-center gap-2"
                  htmlFor="parent_mobile"
                >
                  Parent No. 1{" "}
                  <span className="text-red-700 text-3xl font-bold">*</span>
                </label>
                <div className="relative w-full">
                  <input
                    className="signin-inputs pl-4   w-full "
                    type="number"
                    id="parent_mobile"
                    placeholder="Parent No. 1"
                    name="parent_mobile"
                    autoComplete="on"
                    {...register("parent_mobile", {
                      required: true,
                      pattern: /^[\d]{11}/,
                      maxLength: 11,
                    })}
                  />
                  <FontAwesomeIcon
                    className="absolute bottom-1/2 right-4 h-6  w-7 translate-y-1/2"
                    icon={faWhatsapp}
                  />
                </div>
              </div>
              {errors.parent_mobile && (
                <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                  {errors.parent_mobile.type === "required" &&
                    "Please fill out this field"}
                  {errors.parent_mobile.type === "pattern" &&
                    "Please enter an 11-digit phone number"}
                  {errors.parent_mobile.type === "maxLength" &&
                    "Please enter an 11-digit phone number"}
                </p>
              )}

              {
                //!-------server errors -----

                state.errors && state.errors.parent_mobile && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.parent_mobile[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.parent_mobile && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {
                        StudentEditErrors?.response?.data.errors
                          .parent_mobile[0]
                      }
                    </p>
                  )
              }
            </div> */}

            <div className="  flex w-full flex-col items-start justify-center gap-2">
              <label
                className="text-start flex items-center gap-2"
                htmlFor="parent_mobile"
              >
                Username
                <span className="text-red-700 text-3xl font-bold">*</span>
              </label>
              <input
                className="signin-inputs max-w-60 "
                type="text"
                id="username"
                placeholder="User name"
                name="username"
                autoComplete="on"
                {...register("username", {
                  required: true,
                  pattern: /^[A-Za-z0-9-_]*$/,
                  maxLength: 20,
                  minLength: 3,
                })}
              />
              {errors.username && (
                <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                  {errors.username.type === "required" &&
                    "Please fill out this field"}
                  {errors.username.type === "pattern" &&
                    "Please enter an 11-digit phone number"}
                  {errors.username.type === "maxLength" &&
                    "Please enter an 11-digit phone number"}
                </p>
              )}

              {
                //!-------server errors -----

                state.errors && state.errors.username && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.username[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.username && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.username[0]}
                    </p>
                  )
              }
            </div>
          </div>

          {/**  passwords fields */}
          {!edit && (
            <div className="flex w-full items-start justify-between gap-6 md:flex-col">
              {/** Password  */}
              <div className="w-1/2    md:w-full">
                <div className="relative flex flex-col items-start justify-center gap-2 ">
                  <label
                    className="text-start flex items-center gap-2"
                    htmlFor="password"
                  >
                    Password
                    <span className="text-red-700 text-3xl font-bold">*</span>
                  </label>
                  <div
                    //!input password relative
                    className="relative-hide relative h-auto w-full"
                  >
                    <input
                      id="password"
                      className="signin-inputs pl-4   w-full"
                      type={`${state.hide ? "text" : "password"}`}
                      placeholder="Password"
                      name="password"
                      autoComplete="on"
                      {...register("password", {
                        required: true,
                        maxLength: 25,
                        minLength: 6,
                      })}
                    />

                    <div
                      //!eye icons
                      onClick={() =>
                        dispatch({
                          type: "setHide",
                        })
                      }
                      className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                    >
                      {state.hide ? (
                        <FontAwesomeIcon
                          className="h-full w-full"
                          icon={faEye}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="h-full w-full"
                          icon={faEyeSlash}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {errors.password && (
                  <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                    {errors.password.type === "required" &&
                      "Please fill out this field"}
                    {errors.password.type === "maxLength" &&
                      "The maximum number of characters is 25 characters"}
                    {errors.password.type === "minLength" &&
                      "The minimum number of characters is 6 characters"}
                  </p>
                )}

                {
                  //!-------server errors -----

                  state.errors && state.errors.password && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.password[0]}
                    </p>
                  )
                }
              </div>

              {/** Confirm Password  */}
              <div className="w-1/2     md:w-full">
                <div className="relative flex flex-col items-start justify-center gap-2     ">
                  <label
                    className="text-start flex items-center gap-2"
                    htmlFor="password_confirmation"
                  >
                    Confirm the password
                    <span className="text-red-700 text-3xl font-bold">*</span>
                  </label>

                  <div
                    //!input password relative
                    className="relative-hide relative h-auto w-full"
                  >
                    <input
                      id="password_confirmation"
                      className="signin-inputs pl-4   w-full"
                      type={`${state.hide ? "text" : "password"}`}
                      placeholder="Confirm the password"
                      name="password_confirmation"
                      autoComplete="on"
                      {...register("password_confirmation", {
                        required: true,
                        maxLength: 25,
                        minLength: 6,
                        validate: (value) => value === getValues("password"),
                      })}
                    />

                    <div
                      //!eye icons
                      onClick={() =>
                        dispatch({
                          type: "setHide",
                        })
                      }
                      className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                    >
                      {state.hide ? (
                        <FontAwesomeIcon
                          className="h-full w-full"
                          icon={faEye}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="h-full w-full"
                          icon={faEyeSlash}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {errors.password_confirmation && (
                  <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                    {errors.password_confirmation.type === "required" &&
                      "Please fill out this field"}
                    {errors.password_confirmation.type === "maxLength" &&
                      "The maximum number of characters is 25 characters"}
                    {errors.password_confirmation.type === "minLength" &&
                      "The minimum number of characters is 6 characters"}
                    {errors.password_confirmation.type === "validate" &&
                      "Passwords do not match"}
                  </p>
                )}

                {
                  //!-------server errors -----

                  state.errors && state.errors.password_confirmation && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.password_confirmation[0]}
                    </p>
                  )
                }
              </div>
            </div>
          )}

          {edit && (
            <div className="flex flex-row-reverse w-full items-start justify-between gap-16 md:flex-col md:gap-6 ">
              {/** student WhatsUp field */}

              <div className="w-full   md:w-full">
                <div className="flex w-full flex-col items-start justify-center gap-2">
                  <label className="text-start" htmlFor="mobile">
                    Student phone number
                  </label>
                  <div className="relative w-full">
                    <input
                      className="signin-inputs pl-4   w-full"
                      type="text"
                      id="mobile"
                      placeholder="Student phone number"
                      name="mobile"
                      autoComplete="on"
                      {...register("mobile", {
                        required: true,
                        pattern: /^[\d]{11}/,
                        maxLength: 11,
                      })}
                    />
                    <FontAwesomeIcon
                      className="absolute bottom-1/2 right-4 h-6  w-7 translate-y-1/2"
                      icon={faWhatsapp}
                    />
                  </div>
                </div>
                {errors.mobile && (
                  <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                    {errors.mobile.type === "required" &&
                      "Please fill out this field"}
                    {errors.mobile.type === "pattern" &&
                      "Please enter an 11-digit phone number"}
                    {errors.mobile.type === "maxLength" &&
                      "Please enter an 11-digit phone number"}
                  </p>
                )}

                {
                  //!-------server errors -----

                  state.errors && state.errors.mobile && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.mobile[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.mobile && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {StudentEditErrors?.response?.data.errors.mobile[0]}
                      </p>
                    )
                }
              </div>
            </div>
          )}

          {/** Governorate and City fields */}
          {/* <div className="flex flex-row-reverse w-full items-start justify-between gap-6 md:flex-col-reverse">
            <div className=" flex w-1/2 md:w-full flex-col items-start justify-center gap-2">
              <>
                <label
                  className="text-start flex items-center gap-2"
                  htmlFor="area"
                >
                  Cities
                  <span className="text-red-700 text-3xl font-bold">*</span>
                </label>
                <select
                  name="area"
                  id="area"
                  defaultValue="15 مايو"
                  {...register("area", { required: true })}
                >
                  {state?.cities?.map((city) => (
                    <option
                      key={city.id}
                      id={city.id}
                      value={city.id}
                      selected={
                        StudentInfo?.data[0]?.area
                          ? StudentInfo?.data[0]?.area === city.id
                          : city.id === 1
                      }
                    >
                      {city.city_name_ar}
                    </option>
                  ))}
                </select>
                {errors.area && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.area.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.area && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.area[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.area && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {StudentEditErrors?.response?.data.errors.area[0]}
                      </p>
                    )
                }
              </>
            </div>

            <div className=" flex w-1/2 md:w-full flex-col items-start justify-center gap-2 md:w-full ">
              <>
                <label
                  className="text-start flex items-center gap-2"
                  htmlFor="governorate"
                >
                  Governorate
                  <span className="text-red-700 text-3xl font-bold">*</span>
                </label>
                <select
                  name="governorate"
                  id="governorate"
                  defaultValue="القاهرة"
                  {...register("governorate", { required: true })}
                >
                  {state?.governorate?.map((city) => (
                    <option
                      key={city.id}
                      value={city.state}
                      selected={
                        StudentInfo?.data[0]?.governorate
                          ? StudentInfo?.data[0]?.governorate === city.state
                          : city.id === 1
                      }
                    >
                      {city.state}
                    </option>
                  ))}
                </select>
                {errors.governorate && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.governorate.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.governorate && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.governorate[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.governorate && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {
                          StudentEditErrors?.response?.data.errors
                            .governorate[0]
                        }
                      </p>
                    )
                }
              </>
            </div>
          </div> */}

          {/* <div className="flex flex-row w-full items-start justify-between gap-16 md:flex-col md:gap-6"> */}
          {/* <div className="flex w-1/2 flex-col  items-center    md:w-full">
              <label
                className="text-start flex items-center gap-2"
                htmlFor="Type"
              >
                Type
                <span className="text-red-700 text-3xl font-bold">*</span>
              </label>
              <div className="flex w-full gap-20 pb-1 sm:flex-col-reverse">
                <div className=" flex  items-center justify-center gap-2 ">
                  <input
                    className="cursor-pointer"
                    type="radio"
                    id="ig"
                    value="0"
                    {...register("edu_type", { required: true })}
                  />
                  <label className="cursor-pointer text-start" htmlFor="ig">
                    IG
                  </label>
                </div>

                <div className=" flex  items-center justify-center gap-2 ">
                  <input
                    className="cursor-pointer"
                    type="radio"
                    id="national"
                    value="1"
                    {...register("edu_type", { required: true })}
                  />
                  <label
                    className="cursor-pointer text-start"
                    htmlFor="national"
                  >
                    National
                  </label>
                </div>
              </div>
              {errors.section && (
                <p className="w-full text-start text-[12px] text-blue-900  ">
                  {errors.section.edu_type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.edu_type && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.edu_type[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.edu_type && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.edu_type[0]}
                    </p>
                  )
              }
            </div> */}

          {/* 
            {watchType ? (
              <div className=" flex w-1/2 flex-col items-start justify-center gap-2 md:w-full">
                <label
                  className="text-start flex items-center gap-2"
                  htmlFor="stage"
                >
                  Stage
                  <span className="text-red-700 text-3xl font-bold">*</span>
                </label>
                <select
                  name="stage"
                  id="stage"
                  placeholder="Select Stage"
                  {...register("stage", { required: true })}
                >
                  {watchType === "0" ? (
                    <>
                      <option value="9">Nine Year</option>
                      <option value="10">Ten Year</option>
                    </>
                  ) : (
                    <>
                      {edit ||
                        (addNew && (
                          <>
                            <option value="22">Second preparatory</option>
                            <option value="33">Third preparatory </option>
                          </>
                        ))}
                      <option value="1">First Secondary</option>
                      <option value="2">Second Secondary</option>
                      <option value="3">Third Secondary</option>
                    </>
                  )}
                </select>
                {errors.stage && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.stage.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.stage && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.stage[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.stage && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {StudentEditErrors?.response?.data.errors.stage[0]}
                      </p>
                    )
                }
              </div>
            ) : null} */}
          {/* </div> */}

          {/** sciences or math fields */}

          <div className="flex w-full flex-col  items-center    md:w-full">
            {+watchLevel === 2 || +watchLevel === 3 ? (
              <>
                <div className="flex w-full items-end gap-20 pb-1 sm:flex-col-reverse">
                  {/** scientific  */}

                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="scientific"
                      value="scientific"
                      {...register("section", { required: true })}
                    />
                    <label
                      className="cursor-pointer text-start"
                      htmlFor="scientific"
                    >
                      Scientific
                    </label>
                  </div>

                  {/** arts  */}
                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="arts"
                      value="arts"
                      {...register("section", { required: true })}
                    />
                    <label className="cursor-pointer text-start" htmlFor="arts">
                      Arts
                    </label>
                  </div>
                </div>
                {errors.section && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.section.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.section && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.section[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.section && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {StudentEditErrors?.response?.data.errors.section[0]}
                      </p>
                    )
                }
              </>
            ) : (
              ""
            )}
          </div>

          {/** groups and center or group  fields */}
          <div className="flex flex-row w-full items-start justify-between gap-16 md:flex-col md:gap-6">
            {/** center or group */}
            {/* <div className=" flex w-1/2 md:w-full flex-col items-start justify-center gap-2 ">
              <label
                className="text-start flex items-center gap-2"
                htmlFor="is_online"
              >
                Place
                <span className="text-red-700 text-3xl font-bold">*</span>
              </label>
              <select
                name="is_online"
                id="is_online"
                {...register("is_online", { required: true })}
              >
                <option value="1">Online</option>
                <option value="0">Center</option>
              </select>
              {errors.is_online && (
                <p className="w-full text-start text-[12px] text-blue-900  ">
                  {errors.is_online.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.is_online && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.is_online[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.is_online && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.is_online[0]}
                    </p>
                  )
              }
            </div> */}

            <div className=" flex w-full flex-col items-start justify-center gap-2    md:w-full">
              {/* {watchPlace === "0" || watchPlace === 0 ? ( */}
              <>
                <label htmlFor="group_id">Group</label>
                <select
                  name="group_id"
                  id="group_id"
                  {...register("group_id", {
                    required: edit || addNew ? false : true,
                  })}
                >
                  {state.groups
                    // ?.filter(
                    //   (group) => parseInt(group.stage) === parseInt(watchLevel)
                    // )
                    .map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                </select>

                {errors.group_id && (
                  <p className="w-full text-end text-[12px] text-red-900  ">
                    {errors.group_id.type === "required" &&
                      "برجاء ملئ هذا الحقل"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.group_id && (
                    <p className="w-full text-end text-[12px] text-red-900  ">
                      {state.errors.group_id[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.group_id && (
                      <p className="w-full text-end text-[12px] text-red-900  ">
                        {StudentEditErrors?.response?.data.errors.group_id[0]}
                      </p>
                    )
                }
              </>
              {/* ) : (
                ""
              )} */}
            </div>
          </div>

          {/** active and magnetic_id fields */}
          {edit && (
            <div className="flex flex-row-reverse w-full items-center justify-between gap-16 md:flex-col md:gap-6">
              {/** active filed */}
              <div className=" active flex w-1/2 flex-col items-end justify-center gap-2 md:w-full ">
                <div className="flex w-full items-center justify-end gap-10 md:justify-center">
                  <label className="visibility-switch order-2 text-start">
                    <input
                      className=""
                      id="active"
                      name="active"
                      {...register("active", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate order-1 text-start"
                      htmlFor="active"
                    >
                      Activation
                    </label>
                  </div>
                </div>

                {errors.active && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors.active.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.active && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.active[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.active && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {StudentEditErrors?.response?.data.errors.active[0]}
                      </p>
                    )
                }
              </div>
              {/**magnetic_id */}
              <div className=" flex w-1/2 flex-col items-start justify-center  gap-2    md:w-full">
                <label className="text-start" htmlFor="magnetic_id">
                  Activation card
                </label>
                <input
                  className="signin-inputs pl-4    w-full"
                  type="text"
                  id="magnetic_id"
                  placeholder="Activation card"
                  name="magnetic_id"
                  autoComplete="on"
                  {...register("magnetic_id", {
                    pattern: /^[A-Za-z0-9-_]*$/,
                    minLength: 3,
                  })}
                />
                {errors.magnetic_id && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.magnetic_id.type === "required" &&
                      "Please fill out this field"}
                    {errors.magnetic_id.type === "pattern" &&
                      "Only English letters and numbers are allowed"}
                    {errors.magnetic_id.type === "maxLength" &&
                      "The maximum number of characters is 20 characters"}
                    {errors.magnetic_id.type === "minLength" &&
                      "Please write at least 3 characters"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.magnetic_id && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.magnetic_id[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.magnetic_id && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {
                          StudentEditErrors?.response?.data.errors
                            .magnetic_id[0]
                        }
                      </p>
                    )
                }
              </div>
            </div>
          )}

          {/* account */}
          {/* <div className="flex w-full items-start justify-between gap-12 md:flex-col  md:gap-6">
            <div className="flex w-full flex-col items-start justify-center gap-2">
              <label className="text-start" htmlFor="account">
                Account (Facebook or Instagram)
                <span className="text-red-700 text-3xl font-bold">*</span>
              </label>
              <input
                className="signin-inputs pl-4    w-full"
                type="url"
                id="account"
                placeholder="Account"
                name="account"
                autoComplete="on"
                {...register("account", {
                  required: true,
                  minLength: 4,
                  pattern: {
                    value:
                      /^(https?:\/\/)?([\w\d-]+\.)+[\w-]+(\/[\w\d-./?%&=]*)?$/,
                    message: "Please enter a valid URL",
                  },
                })}
              />
              {errors.account && (
                <p className="w-full text-start text-[12px] text-blue-900  ">
                  {errors.account.type === "required" &&
                    "Please fill out this field"}

                  {errors.account.type === "minLength" &&
                    "Please write at least 4 characters"}
                  {errors.account.type === "pattern" && errors.account.message}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.account && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.account[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.school && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.school[0]}
                    </p>
                  )
              }
            </div>
          </div> */}

          {/*           
          <div className="flex w-full items-start justify-between gap-12 md:flex-col  md:gap-6">
            <div className="flex w-full flex-col items-start justify-center gap-2">
              <label className="text-start" htmlFor="school">
                School{" "}
                <span className="text-red-700 text-3xl font-bold">*</span>
              </label>
              <input
                className="signin-inputs pl-4    w-full"
                type="text"
                id="school"
                placeholder="School"
                name="school"
                autoComplete="on"
                {...register("school", {
                  required: true,
                  maxLength: 35,
                  minLength: 4,
                })}
              />
              {errors.school && (
                <p className="w-full text-start text-[12px] text-blue-900  ">
                  {errors.school.type === "required" &&
                    "Please fill out this field"}

                  {errors.school.type === "maxLength" &&
                    "The maximum number of characters is 35 characters"}
                  {errors.school.type === "minLength" &&
                    "Please write at least 4 characters"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.school && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.school[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.school && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.school[0]}
                    </p>
                  )
              }
            </div>
          </div> */}
          {/** User Image  */}
          {/* {!edit && (
            <div className="flex w-full flex-col items-end gap-2">
              <label
                className="text-start flex items-center gap-2"
                htmlFor="profile_pic"
              >
                Upload a personal photo (Max Size 50 MB only Jpg/Jpeg/Png/Webp )
              </label>

              <input
                id="profile_pic"
                className="signin-inputs pl-4    w-full"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/webp"
                name="profile_pic"
                {...register("profile_pic", {
                  required: false,
                  validate: (value) => !(value[0]?.size > 50000000),
                })}
              />

              {errors.profile_pic && (
                <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                  {errors.profile_pic.type === "required" &&
                    "Please add a personal photo"}
                  {errors.profile_pic.type === "validate" &&
                    "Maximum image size is 50MB"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.profile_pic && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.profile_pic[0]}
                  </p>
                )
              }
            </div>
          )} */}
          {/** Submit Button */}
          <button
            disabled={!isValid || state.submitLoading}
            className="submit mt-6 w-full "
            type="submit"
          >
            {state.submitLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : edit ? (
              "Modify data"
            ) : (
              "Create account"
            )}
          </button>
        </form>
        {!edit ||
          (addNew && (
            <div className="my-10 w-full text-center text-[14px]  ">
              Already have an account?
              <Link className=" font-bold" to="/signin">
                Sign in now
              </Link>
            </div>
          ))}
      </div>
    </section>
  );
}

export default Register;
